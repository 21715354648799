

@media screen and (max-width: 1200px) {
    .carousel-item {height: 780px;}
    .carousel-caption{top:22rem;}
    .carousel-caption h1{font-size:48px;}
    .iphoneapp {max-width:32%;}
    }
    
    @media screen and (max-width: 992px) {
    .carousel-caption .appbtn{max-width:25%;}
    .carousel-caption h1{ letter-spacing:1px;}
    .carousel-caption{ position:static; text-align:center !important; display:block; margin-top:15%;}
    .logo img{max-width:35%;}
    .loginbtn{padding: 8px 20px; font-size: 18px; margin: 22px 0 0 0;}
    .iphoneapp{ position:static; text-align:center; display:block; margin:auto;}
    .carousel-caption h1{text-shadow: 2px 2px 2px #000; font-size: 32px;}
    .carousel-caption p{margin-top: 20px;}
    .carousel-item.item1{background: url(../images/banner1.jpg) no-repeat center;}
    .carousel-item.item2{background: url(../images/banner2.jpg) no-repeat center;}
    .iphoneapp {max-width:54%;}
    .rating span, .users span, .contest span{font-size: 72px;}
    .rating p, .users p, .contest p{font-size: 22px;}
    h2{ font-size:42px; padding-bottom:40px;}
    .select-match .title{font-size: 22px; line-height: 24px;}
    .about .pt20{ padding-top:0;}
    footer p{font-size:14px;}
    .footer_link a{ font-size:15px;}
    footer .text-company{font-size: 18px;}
    .title_social{font-size: 18px;}
    footer .appbtn a img{max-width:28%;}
    footer .copyright .container {padding: 20px 15px;}
    .copyright_text{ font-size:13px;}
    
    .btnMenu{ display:block;}
    .home-nav{ display:none;}
    .contenedor-menu .home-nav{display:block;}
    #innerpage .logo img{ max-width:80%;}
    }
    
    
    @media screen and (max-width: 767px) {
    /*.carousel-item {height: 640px;}*/
    
    .carousel-item.item1{background: url(../images/banner1.jpg) no-repeat center top; background-size:120%;}
    .carousel-item.item2{background: url(../images/banner2.jpg) no-repeat center top; background-size:120%;}
    .logo img{ max-width:48%;}
    /*.carousel-caption{margin-top: 35%;}*/
    .loginbtn {padding:5px 10px; font-size: 14px; margin:15px 0 0 0; border-radius:4px;}
    /*.carousel-caption p.smalltxt {margin-top: 15px; font-size: 16px;}*/
    .carousel-caption .appbtn{max-width:34%;}
    .rating, .users{ margin-bottom:50px;}
    section.easy-steps, section.faq{padding: 50px 0px;}
    h2{font-size: 36px; padding-bottom: 30px;}
    .select-match{ margin-bottom:40px;}
    .about{ text-align:justify;}
    .getapplink .title{ font-size:18px;}
    .getlinkbtn{ font-size:16px;}
    section.testimonial{padding: 40px 0px;}
    section.about{ padding-bottom:0;}
    .accordion-button{ font-size:19px;}
    .footer_logo img{ max-width:50%;}
    footer .container{padding:40px 15px;}
    .footer_link li{ margin:5px 0px;}
    .copyright_text, footer .appbtn { text-align:center; display:block;}
    .copyright_text{ margin-bottom:15px;}
    
    .login-container{max-width:none; margin:0 10px 0px 10px; position:static;}
    
    .carousel-item {height: 254px;}
    .carousel-caption h1{font-size:18px;}
    .iphoneapp{ display:none;}
    .carousel-caption{margin-top: 20%;}
    .carousel-caption p.smalltxt {margin: 8px 0; font-size: 13px;}
    .carousel-indicators{ bottom:-10px;}
    
    #innerpage h2{padding: 20px 0px 20px 0;}
    /*#innerpage main{padding-top:100px;}*/
    #innerpage .innercontent{padding: 18px;}
    }
    
    @media screen and (max-width: 512px) {
        
    }
    
    @media screen and (max-width: 480px)  {
        
    }