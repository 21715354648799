@charset "utf-8";
/* CSS Document */
body{
	padding:0;
	margin:0;
	position:relative;
	font-family: 'dinotregular';
	font-size:16px;
}

@font-face {
 font-family: 'dinotregular';
 src: url('../fonts/dinot-regular-webfont.eot');
 src: url('../fonts/dinot-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/dinot-regular-webfont.woff') format('woff'),
   url('../fonts/dinot-regular-webfont.ttf') format('truetype');

    /* url('../fonts/dinot-regular-webfont.svg#dinotregular') format('svg'); */
 font-weight: normal;
 font-style: normal;
}
 @font-face {
 font-family: 'dinotmedium';
 src: url('../fonts/dinot-medium-webfont.eot');
 src: url('../fonts/dinot-medium-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/dinot-medium-webfont.woff') format('woff');
   /* url('../fonts/dinot-medium-webfont.ttf') format('truetype'); */
    /* url('../fonts/dinot-medium-webfont.svg#dinotmedium') format('svg'); */
 font-weight: normal;
 font-style: normal;
}
 @font-face {
 font-family: 'dinotbold';
 src: url('../fonts/dinot-bold-webfont.eot');
 src: url('../fonts/dinot-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/dinot-bold-webfont.woff') format('woff');
   /* url('../fonts/dinot-bold-webfont.ttf') format('truetype'); */
    /* url('../fonts/dinot-bold-webfont.svg#dinotbold') format('svg'); */
 font-weight: normal;
 font-style: normal;
}
a{
	text-decoration:none;
	outline:none;
}
ol, ul {
    padding-left: 0;
}
header.home{
	position:absolute;
	top:0;
	z-index:100;
	width:100%;
}
.loginbtn{
	float:right;
	background-color:#3aaf44;
	padding:10px 30px;
	font-family: 'dinotbold';
	font-size:20px;
	color:#fff;
	margin:40px 0 0 0;
	text-decoration:none;
	border-radius:8px;
}
.loginbtn:hover{
	color:#fff;
	background-color:#2ea438;
}
section.greenstrip{
	background-color:#3aaf44;
	padding:40px 0px;
	font-family: 'dinotmedium';
}
.rating{
	background:url(../images/rating.png) no-repeat top;
	transition: all .2s ease-in-out;
}
.users{
	background:url(../images/users.png) no-repeat top;
	transition: all .2s ease-in-out;
}
.contest{
	background:url(../images/contest.png) no-repeat top;
	transition: all .2s ease-in-out;
}
.rating:hover{background: url(../images/rating.png) no-repeat center 18px;}
.users:hover{background: url(../images/users.png) no-repeat center 18px;}
.contest:hover{background: url(../images/contest.png) no-repeat center 18px;}

.rating span, .users span, .contest span{
	font-size:85px;
	line-height:95px;
	color:#fff;
	padding-top:20px;
	display:block;
}
.rating p, .users p, .contest p{
	font-size:30px;
	color:#fff;
}
section.easy-steps, section.faq{
	padding:80px 0px;
}
section.about{
	padding:0px 0 80px 0px;
}
section.testimonial{
	padding:80px 0px;
	background-color:#3aaf44;
}
h2{
	font-size:50px;
	color:#1c1c1c;
	font-family: 'dinotbold';
	text-align:center;
	padding:0px 0px 60px 0;
}
h2.white{
	color:#fff;
}
h2 span{
	color:#3aaf44;
}

.select-match{
	text-align:center;
}
.select-match i{
	background-color:#f3f3f5;
	height:154px;
	line-height:154px;
	width:162px;
	display:block;
	text-align:center;
	border-radius:10px;
	margin:auto;
	margin-bottom:25px;
}
.select-match .title{
	font-size:26px;
	color:#1c1c1c;
	font-family: 'dinotbold';
	padding-bottom:8px;
	display:block;
}
.getapplink{
	margin:20px 0px;
}
.getapplink .title{
	font-size:24px;
	color:#1c1c1c;
	font-family: 'dinotbold';
	display:block;
	padding-bottom:14px;
}
.getlinkbtn{
	font-size:20px;
	font-family: 'dinotbold';
	color:#fff;
	background-color:#3aaf44;
	border:none;
}
.getlinkbtn:hover, .getlinkbtn:focus{
	color:#fff;
	background-color:#2ea438;
}
.owl-carousel .item{
	background-color:#f3f3f5;
	border-radius:10px;
	padding:20px;
	margin:10px;
	box-shadow: -3.225px 8.402px 5px 0px rgba(0, 0, 0, 0.08);
	-webkit-box-shadow: -3.225px 8.402px 5px 0px rgba(0, 0, 0, 0.08);
	position:relative;
}
.owl-carousel .item .user-img{
	width:100px;
	height:100px;
	border-radius:50%;
	margin-top:-44px;
}
.owl-carousel h4{
	font-size:24px;
	color:#1c1c1c;
	font-family: 'dinotbold';
	padding:20px 0 0 0px;
}
.owl-carousel .owl-item{
	padding-top:15px;
}
.owl-theme .owl-dots .owl-dot span{
	width: 14px !important;
    height: 14px !important;
	border:2px solid #fff;
	background-color:transparent !important;
	background:transparent !important;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
	background:#fff !important;
}
.accordion-item{
	background:none;
	border:none;
	border-bottom:1px solid #e7e8eb;
}
.accordion-button:not(.collapsed) {
    color: #1c1c1c;
    background-color: transparent;
    box-shadow: none;
}
.accordion-button{
	font-size:22px;
	font-family: 'dinotmedium';
	color: #1c1c1c;
	padding:20px 0px;
}
.accordion-body{
	padding:0px 0px 20px 0px;
}
.accordion-button:focus {
    z-index: 3;
	border:none;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
}
.accordion-button::after{
	position: absolute;
	top: 30px;
	right:0;
	background-image: url(../images/up-arrow.svg);
	width: 24px;
    height: 24px;
	transform: translateY(-50%) rotate(180deg);
	background-size:auto;
	background-position: 50%;
	transition: transform .3s ease;
}
.accordion-button:not(.collapsed)::after{
	position: absolute;
	top: 30px;
	right:0;
	background-image: url(../images/up-arrow.svg);
	width: 24px;
    height: 24px;
	transform: translateY(-50%) rotate(0deg);
	background-size:auto;
	background-position: 50%;
	transition: transform .3s ease;
}
.pt20{
	padding-top:20px;
}

footer {
    width: 100%;
    background: #232627;
}
footer .container{
	padding:50px 0px;
}
footer .text-company{
	padding:20px 0px 5px 0px;
	color:#fff;
	font-size:22px;
	font-family: 'dinotbold';
	display:block;
}
footer p{
	color:#fff;
}
.footer_link { list-style:none;}
.footer_link li{ min-width: calc(50% - 1rem); margin: .18rem 0.5rem;}
.footer_link a{ font-size:1.14rem; color:#fff;}
.footer_link a:hover{ color:#2eb137;}
.title_social {
    color: #fff;
    font-size: 22px;
    font-family: 'dinotbold';
}

.socail_link a{ border: .1rem solid #ffffff;height: 2.87rem; width: 2.78rem; align-items: center; display: inline-flex; justify-content: center; color:#fff; font-size:1.4rem;}
.socail_link a:hover{color:#2eb137; background:#fff;}
.copyright{
	background-color:#3aaf44 !important;
}
.copyright_text {
    color: #fff !important;
    font-size: 16px !important;
	align-items:center !important;
	display:flex !important;
}
.appbtn{
	text-align:right !important;
	display:flex;
}
.appbtn a{
	margin-left:10px;
}
footer .copyright .container{
	padding:30px 0px;
}


section.login{
	/*background:url(../images/loginbg.jpg) no-repeat center;*/
	/*background-size:cover;*/
	height:100%;
}
.loginbg{
	position:fixed;
	width:100%;
	height:100%;
	z-index:-1;
}
.login-logo{
	display:block !important;
	text-align:center;
	padding:15px 0;
}
body, html, main{
  min-height:100%;
  height: 100%;
  margin: 0;
  position:relative;
}

.login-container {
    /*position: absolute;
    right: 0;
	left:0;
	top:13%;*/
    margin: 20px auto;
    max-width: 450px;
    padding:30px;
    background-color: white;
	border-radius: 10px;
	box-shadow: -1.873px 4.636px 13px 0px rgba(0, 0, 0, 0.24);
	-webkit-box-shadow: -1.873px 4.636px 13px 0px rgba(0, 0, 0, 0.24);
}
.login-container h2{
	font-size:24px;
	color:#1c1c1c;
	font-family: 'dinotbold';
	text-align:left;
	padding-bottom:20px;
	margin:0;
}

.btn-primary{
	background-color:#3aaf44;
	font-size:20px;
	font-family: 'dinotbold';
	color:#fff;
	border:none;
	outline:none;
}
.btn-primary:hover, .btn-primary:focus{
	background-color:#2b9e35;
}
.sepration{
	text-align:center;
	display:block;
	margin:30px auto;
	border-bottom:1px solid #d2d2d2;
	max-width:50%;
	position:relative;
}
.sepration span{
	display: block;
    background-color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 32px;
    height: 20px;
    bottom: -9px;
}
.social{
	text-align:center;
	margin:0px 0;
}
.social .reg-fb{
	display:inline-block;
	border:3px solid #4d66a4;
	width:52px;
	height:52px;
	line-height:50px;
	text-align:center;
	border-radius:50%;
	margin:0 10px;
}
.social .reg-fb .fa{
	font-size:24px;
	color:#4d66a4;
}
.social .reg-google{
	display:inline-block;
	border:3px solid #d31313;
	width:52px;
	height:52px;
	line-height:50px;
	text-align:center;
	border-radius:50%;
	margin:0 10px;
}
.social .reg-google .fa{
	font-size:24px;
	color:#d31313;
}
.tnc{
	margin:15px 0;
	text-align:center;
	font-size:13px;
}
.tnc a{
	color:#3aaf44;
}
.actiontxt{
	margin:20px 0 0 0;
	text-align:center;
	font-size:20px;
	font-family: 'dinotmedium';
}
.actiontxt a{
	color:#3aaf44;
}
.spacer{
	height:30px;
}
.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.1); }

/******Navigation*********/
.home-nav{
	float:right;	
}
.home-nav ul{
	list-style:none;
	margin:50px 20px 0px 0px;
}
.home-nav ul li{
	display:inline-block;
}
.home-nav ul li a{
	color:#1c1c1c;
	padding:10px 10px;
	border-radius:4px;
}
.home-nav ul li a:hover{
	color:#1c1c1c;
	background-color: rgba(255, 255, 255, 0.7);
}
.btnMenu{display:none;}
.btnMenu, .btnMenu:hover{
	color:#3aaf44;
	font-size:20px;
	padding:10px;
}

/******header innerpage*********/

#innerpage header{
	height: 148px !important;
	/* background-color: #3aaf44 !important; */
	text-align:center !important;
}
#innerpage main{
	padding-top:128px !important;
}
#innerpage .innercontent{
	padding:30px !important;
}
#innerpage h2{
	font-size:40px !important;
	color:#1c1c1c !important;
	padding: 20px 0px 40px 0 !important;
}
#innerpage h2 span{
	color:#3aaf44 !important;
}
h3{
	font-size:22px;
	color:#1c1c1c;
	font-family: 'dinotbold';
	padding-bottom:10px;
	padding-top:10px;
}


.appbtn.rside{
	margin:25px 0 0 0;
}
.appbtn.rside a{
	display:block;
	float:left;
	margin:0px 10px 15px 0px;
	text-align:left;
}

.getlinkbtn{
	font-size:20px;
	font-family: 'dinotbold';
	color:#fff !important;
	background-color:#3aaf44 !important;
	border:none;
}
.getlinkbtn:hover, .getlinkbtn:focus{
	color:#fff;
	background-color:#2ea438;
}
.home-nav{
	float:right;	
}
.home-nav ul{
	list-style:none;
	margin:50px 20px 0px 0px;
}
.home-nav ul li{
	display:inline-block;
}
.home-nav ul li a{
	color:#1c1c1c;
	padding:10px 10px;
	border-radius:4px;
}
.home-nav ul li a:hover{
	color:#1c1c1c;
	background-color: rgba(255, 255, 255, 0.7);
}
